import React, { Fragment } from "react";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { BaseFlex, InfoIcon } from "@components/common";
import { UserLiquidity, Pool, UserLiquidityFallback, PoolTableFallback } from "@components/ui";
import useIsMobile from "@lib/hooks/useIsMobile";
import { LPPositionsPoller } from "@components/helper";

import { ErrorBoundary } from "@sentry/react";

export const PageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem",
  maxWidth: "65.625rem",
  margin: "auto",
});

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1em",
  fontWeight: 500,
  color: "#FFF",
  marginBottom: "1.25em",

  fontSize: "17px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "19px",
  },
}));

export const Tip = ({
  link,
  description,
  isMobile,
}: {
  link: string;
  description: string;
  isMobile: boolean;
}): JSX.Element => {
  return (
    <MuiLink href={link} rel="noopener noreferrer" target="_blank">
      <BaseFlex
        sx={{
          justifyContent: isMobile ? "flex-start" : "center",
          margin: isMobile ? "1em 0" : 0,
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          variant="caption2"
          sx={{ color: (theme) => theme.palette.common.white, opacity: 0.76 }}
        >
          {description}
        </Typography>
        <InfoIcon
          sx={{
            fontSize: {
              xs: "0.8125rem",
              xl: "1rem",
            },
          }}
        />
      </BaseFlex>
    </MuiLink>
  );
};

const UserSupplyTable = () => {
  return (
    <div>
      <Header>
        <Typography variant="headline1" sx={{ whiteSpace: "nowrap" }}>
          Your Provided Liquidity
        </Typography>
      </Header>

      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "lending page - user supply table");
        }}
        fallback={<UserLiquidityFallback withLabel={false} />}
      >
        <UserLiquidity withLabel={false} />
      </ErrorBoundary>
    </div>
  );
};

const PoolTable = ({ isMobile = false }: { isMobile?: boolean }) => {
  return (
    <div>
      <Header>
        <BaseFlex
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="headline1" sx={{ whiteSpace: "nowrap" }}>
            Provide Liquidity
          </Typography>

          {!isMobile && (
            <Tip
              isMobile={false}
              link="https://docs.cora.money/user-guides/adding-liquidity"
              description="Learn more about adding liquidity"
            />
          )}
        </BaseFlex>
      </Header>

      <ErrorBoundary
        fallback={<PoolTableFallback />}
        beforeCapture={(scope) => {
          scope.setTag("location", "lending page - pool table");
        }}
      >
        <Pool />
      </ErrorBoundary>

      {isMobile && (
        <Tip
          isMobile={true}
          link="https://docs.cora.money/user-guides/adding-liquidity"
          description="Learn more about adding liquidity"
        />
      )}
    </div>
  );
};

export const Supply = (): JSX.Element => {
  const { isMobile } = useIsMobile();

  return (
    <Fragment>
      <LPPositionsPoller />
      <PageContainer>
        <PoolTable isMobile={isMobile} />
        <UserSupplyTable />
      </PageContainer>
    </Fragment>
  );
};
