import React, { Fragment } from "react";
import { useRouter } from "next/router";
import ButtonBase from "@mui/material/ButtonBase";
import { DownCaret } from "@components/common";
import { ManageLiquidityUI } from "@components/ui";
import { PageContainer, Header } from "./Liquidity";
import ModalManager from "../ManageLiquidity/modalManager/ModalManager";
import { LPPositionsPoller } from "@components/helper";

const ManageLiquidityPage = ({ poolId }: { poolId: string }): JSX.Element => {
  const { back } = useRouter();

  return (
    <Fragment>
      <LPPositionsPoller />
      <PageContainer>
        <ModalManager>
          <div>
            <Header sx={{ justifyContent: "flex-start", gap: "0.5em" }}>
              <ButtonBase onClick={() => back()} sx={{ display: "flex", alignItems: "center" }}>
                <DownCaret sx={{ transform: "rotate(90deg)" }} />
              </ButtonBase>
              Your Provided Liquidity
            </Header>
            <ManageLiquidityUI poolId={poolId} />
          </div>
        </ModalManager>
      </PageContainer>
    </Fragment>
  );
};

export default ManageLiquidityPage;
