import React, { Fragment } from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  UserLiquidity,
  BorrowedAssets,
  CoinPrice,
  UserLiquidityFallback,
  LoansTableFallback,
} from "@components/ui";
import { BaseFlex, TransactionBox, BaseBox } from "@components/common";
import { useIsMobile, useWeb3, useAllTransactions } from "@lib/hooks";
import { useTransactionStore } from "@lib/providers";
import {
  convertTime,
  getExplorerLink,
  HashType,
  generateTransactionStatus,
  hexToRgba,
} from "@lib/utils";
import { LPPositionsPoller } from "@components/helper";
import { TransactionType } from "@lib/types";

import { ErrorBoundary } from "@sentry/react";

const PageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem",
  margin: "1rem auto 0",

  [theme.breakpoints.up("md")]: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: "1.5rem",
  },
}));

const TransactionsContainer = styled("div")(({ theme }) => ({
  padding: 0,
  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Portfolio = (): JSX.Element => {
  const { active, chainId, account } = useWeb3();
  const { isMobile } = useIsMobile();

  const transactions = useAllTransactions();
  const clearTransactions = useTransactionStore((state) => state.clearTransactions);

  return (
    <Fragment>
      <LPPositionsPoller />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <PageContainer>
          <BaseFlex
            sx={{
              flexDirection: "column",
              justifyContent: "flex-start",
              maxWidth: "65.625rem",

              gap: {
                xs: "2.5rem",
                md: "1rem",
                xl: "1.625rem",
              },
            }}
          >
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("location", "portfolio page - user supply table");
              }}
              fallback={<UserLiquidityFallback />}
            >
              <UserLiquidity withLabel />
            </ErrorBoundary>

            <ErrorBoundary
              fallback={<LoansTableFallback withLabel />}
              beforeCapture={(scope) => {
                scope.setTag("location", "portfolio page - loans table");
              }}
            >
              <BorrowedAssets withLabel />
            </ErrorBoundary>
          </BaseFlex>

          {!isMobile && (
            <BaseFlex
              sx={{ flexDirection: "column", gap: "0.625em", justifyContent: "flex-start" }}
            >
              <CoinPrice />

              {active && (
                <BaseBox
                  sx={{
                    borderRadius: "0.5rem",
                    width: "100%",
                    backgroundColor: (theme) => theme.palette.primary[700],
                    color: (theme) => theme.palette.common.white,
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    padding: "1.4063rem 1.5625rem",
                    maxHeight: "50vh",
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "1em",
                      display: "flex",
                    }}
                  >
                    <Typography variant="headline1">Recent Transactions</Typography>

                    {transactions?.length !== 0 && (
                      <Typography
                        variant="body1"
                        component="span"
                        onClick={() =>
                          clearTransactions({
                            chainId: chainId as number,
                            account: account as string,
                          })
                        }
                        sx={{
                          textTransform: "none",
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                      >
                        Clear all
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      justifyContent: "flex-start",
                      maxHeight: "40vh",
                      flexDirection: "column",
                      overflowY: "scroll",
                      marginBottom: "0.5em",
                      scrollbarWidth: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    {transactions.length === 0 ? (
                      <Box
                        sx={{
                          marginTop: "1em",
                          color: (theme) => hexToRgba(theme.palette.common.white, 0.5),
                        }}
                      >
                        Nothing to show
                      </Box>
                    ) : (
                      transactions.map((transaction) => {
                        return (
                          <TransactionsContainer key={transaction.hash}>
                            <TransactionBox
                              variant="dark"
                              transactionTitle={transaction.title}
                              transactionType={transaction.transactionInfo.type}
                              status={generateTransactionStatus({
                                isMined: transaction.isMined,
                                error: transaction.error,
                                isCancelled: !!transaction.isCancelled,
                              })}
                              dateTime={
                                convertTime(transaction.addedTime / 1000).monthDayTime_short
                              }
                              explorerLink={getExplorerLink(
                                transaction.chainId as number,
                                transaction.newHash || transaction.hash,
                                HashType.TRANSACTION
                              )}
                              tokenValue={
                                transaction.transactionInfo.type !== TransactionType.Approve
                                  ? transaction.transactionInfo.transactedAmount?.amount
                                  : undefined
                              }
                              fiatValueUSD={
                                transaction.transactionInfo.type !== TransactionType.Approve
                                  ? (transaction.transactionInfo.transactedAmount
                                      ?.amountInUSD as string)
                                  : undefined
                              }
                            />
                          </TransactionsContainer>
                        );
                      })
                    )}
                  </Box>
                </BaseBox>
              )}
            </BaseFlex>
          )}
        </PageContainer>
      </Box>
    </Fragment>
  );
};

export default Portfolio;
