import React from "react";
import { styled } from "@mui/system";

const PageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem",

  [theme.breakpoints.up("md")]: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: "1.5rem",
  },
}));

export const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: 500,
  color: "#FFF",
  marginBottom: "0.7em",

  fontSize: "25px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "28px",
  },

  "& .tip": {
    color: "white",
    fontSize: "11.7px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "13px",
    },

    "& span": {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
}));

export const TermsOfUse = (): JSX.Element => {
  return (
    <PageContainer>
      <Header>Privacy Policy</Header>
    </PageContainer>
  );
};

export default TermsOfUse;
