import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import { BaseFlex } from "@components/common";
import {
  BorrowedAssets,
  Collateral,
  CollateralTableFallback,
  LoansTableFallback,
} from "@components/ui";
import { Header, PageContainer, Tip } from "./Liquidity";
import useIsMobile from "@lib/hooks/useIsMobile";

import { ErrorBoundary } from "@sentry/react";

const UserBorrowTable = () => {
  return (
    <Fragment>
      <div>
        <Header>
          <BaseFlex
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="headline1">Your Borrowed Balance</Typography>
          </BaseFlex>
        </Header>

        <ErrorBoundary
          fallback={<LoansTableFallback withLabel={false} />}
          beforeCapture={(scope) => {
            scope.setTag("location", "borrow page - loans table");
          }}
        >
          <BorrowedAssets withLabel={false} />
        </ErrorBoundary>
      </div>
    </Fragment>
  );
};

const CollateralTable = ({ isMobile = false }: { isMobile?: boolean }) => {
  return (
    <div>
      <Header>
        <BaseFlex
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="headline1" sx={{ whiteSpace: "nowrap" }}>
            Borrow Stablecoins
          </Typography>

          {!isMobile && (
            <Tip
              isMobile={false}
              link="https://docs.cora.money/user-guides/getting-a-loan"
              description="Learn more about borrowing"
            />
          )}
        </BaseFlex>
      </Header>

      <ErrorBoundary
        fallback={<CollateralTableFallback />}
        beforeCapture={(scope) => {
          scope.setTag("location", "borrow page - collateral table");
        }}
      >
        <Collateral />
      </ErrorBoundary>

      {isMobile && (
        <Tip
          isMobile={true}
          link="https://docs.cora.money/user-guides/getting-a-loan"
          description="Learn more about borrowing"
        />
      )}
    </div>
  );
};

export const Borrow = (): JSX.Element => {
  const { isMobile } = useIsMobile();

  return (
    <PageContainer>
      <CollateralTable isMobile={isMobile} />
      <UserBorrowTable />
    </PageContainer>
  );
};
